<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/customers">Customers</a>
      <small class="fas fa-angle-right mx-1" v-if="this.$route.query.client"></small>
      <a :href="`/customers/${this.$route.query.client.toUpperCase()}`" v-if="this.$route.query.client">{{this.$route.query.client.toUpperCase()}}</a>
    </div>
    <div class="mb-2 d-block d-lg-none d-md-none">
      <div class="btn btn-sm btn-block form-control btn-primary p-2" v-on:click="newCustomer" v-if="['Admin', 'Manager'].includes(this.user.role)">
        <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New Customer
      </div>
    </div>
    <div class="row list-actions">
      <div class="col left-column">
        <!-- <div class="list-tab active" v-on:click="getCustomers()"> -->
        <div class="list-tab active">
          All<span>{{filteredCustomers.length}}</span>&nbsp;&nbsp;
        </div>
      </div>
      <div class="col right-column d-none d-lg-block d-md-block">
        <div class="btn btn-sm btn-primary" v-on:click="newCustomer" v-if="['Admin', 'Manager'].includes(this.user.role)">
          <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New Customer
        </div>
      </div>
    </div>
    <SearchComponent
      @searchRecord="searchRecord($event)"
      :placeholder="'Search customers by name or email'"
      :showSideOptions="false"
      :isRealTime="true"
    ></SearchComponent>
    <ListComponent
      :records="filteredCustomers"
      :profile="'customers'"
    ></ListComponent>
  </div>
</template>

<script>
  import SearchComponent from '../../Search.vue'
  import ListComponent from '../../List.vue'

  export default {
    components: {
      SearchComponent,
      ListComponent
    },
    data() {
      return {
        user: this.$userData.user,
        customers: [],
        filteredCustomers: []
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      async getCustomers(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/users?role=Customer`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.customers = response.data.users
          let queryClient = this.$route.query.client ? this.$route.query.client.trim().toUpperCase() : false
          this.filteredCustomers = this.buildList(this.customers.filter(r => r.client.code === (queryClient || r.client.code)))
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      searchRecord: function(search){
        this.filteredCustomers = this.buildList(this.customers.filter(record => (
          (record.name ? record.name.toLowerCase().match(new RegExp(search.toLowerCase())) : false) ||
          (record.email ? record.email.toLowerCase().match(new RegExp(search.toLowerCase())) : false)
        )))
      },
      validateAvatar: function (avatar){
        return avatar ? this.$getAvatar(avatar) : this.$getAvatar('/images/avatars/avatarArtboard-1.svg')
      },
      buildList: function(records){
        return records.map((record) => {
          return {
            key: record._id,
            avatar: this.validateAvatar(record.avatar),
            header: record.name,
            subheader: record.email,
            indicators: [
              {
                key: `${record._id}_1`,
                text: record.client.code,
                placeholder: record.client.name,
                background: "escalated"
              },
              {
                key: `${record._id}_2`,
                text: record.isActive ? "Active" : "Inactive",
                background: record.isActive ? "primary-default" : "primary-dark"
              }
            ],
            timestamp: {
              createdAt: record.createdAt,
              updatedAt: record.updatedAt
            },
            record: record
          }
        })
      },
      newCustomer: function(){
        this.$router.push({path: "/customers/new"})
      },
      validateUser: async function(){
        if(!['Admin', 'Manager','Agent'].includes(this.user.role)){
          this.$alertify({
            group: 'notification',
            title: `Access denied`,
            type: 'warning',
            text: `You don't have access to this module.`
          })
          await this.$sleep(500);
          window.history.back()
        }
      }
    },
    mounted: function() {
      this.validateUser()
      this.getCustomers();
    }
  }
</script>
